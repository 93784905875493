@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-container.animate {
  animation: fadeInUp 1.5s ease-in-out;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.image-container.animate {
  animation: fadeInLeft 1s ease-in-out;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(10%);
  }
  100% {  
    opacity: 1;
    transform: translateX(0);
  }
}


.bg-animate {
  animation: bg-animate 10s ease-in-out infinite;
}

@keyframes bg-animate {
  0% {
    top: -40;
    rotate: 360deg;
  }
  100% {  
    top: 0;
  }
}